<template>
  <div class="d-flex w-100">
    <TInputSimpleSearch
      placeholder="Search tracking"
      :value.sync="search"
      @update:value="triggerSearch"
      class="w-100 mr-1"
    />
    <SButtonFilter
      :active="!lodash.isEmpty(query)"
      :badge="lodash.size(query)"
      class="ml-auto my-auto"
      @click="show = true"
    />
    <TModal title="Filter" :show="show" @update:show="show = $event">
      <template #actions>
        <SButtonClearFilter
          @click="clearFilter"
          :disabled="loading || lodash.isEmpty(allQuery)"
        />
        <SButtonSearchFilter
          :disabled="loading"
          @click="onFilter"
        />
      </template>

      <CRow>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <TInputText
            label="Order Id"
            placeholder="Order Id"
            :value.sync="filter.id"
            @update:value="filter.id = $event"
            @keydown.enter.native="onFilter"
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <TInputDateTimeRange
            label="Dates"
            :value.sync="dateRange"
            @update:value="setDateRangeFilter"
          />
        </CCol>
      </CRow>
    </TModal>
  </div>
</template>

<script>
import filter from "../mixins/filter";
export default {
  mixins: [filter],
  data() {
    return {
      blockParams: ["tab", "order_id", "action"],
      searchFilter: "by_tracking_code",
      store: "warehouse.assemble_orders",
    };
  },
};
</script>
