<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem class="d-flex">
            <FilterForOrder :queries.sync="query" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(order, index) in orders"
            :key="`${index}${order.id}`"
            :color="order_id_selected == order.id ? 'primary' : 'secondary'"
            :to="_.getAppendRouteQuery($route, { order_id: order.id })"
          >
            <SCardOrderOwningBox :order="order" />
          </CListGroupItem>
          <CListGroupItem v-if="!orders.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="list_fetching">
            <TSpinner :loading="list_fetching" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.assemble_orders" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <CRow v-if="order_id_selected">
        <CCol col="12">
          <CCard>
            <CCardHeader class="d-flex flex-wrap align-items-center">
              <div class="d-flex align-items-center">
                <TMessage
                  content="Suggestion SKUs for order"
                  bold
                  color="muted"
                >
                  <template #suffix>
                    <span class="ml-1">{{ order_id_selected }}</span>
                  </template>
                </TMessage>
                <TSpinner :loading="boxes_available_for_distribution_loading" />
              </div>
              <TButtonRefresh
                class="ml-auto"
                @click="fetchBoxesAvailableForDistribution"
                :options="{
                  disabled: boxes_available_for_distribution_loading,
                }"
              />
            </CCardHeader>
            <CCardBody>
              <div class="scroll-x">
                <TMessageNoData
                  content="No SKU"
                  v-if="!boxes_available_for_distribution.length"
                />
                <div class="d-inline-flex" v-else>
                  <SCardSku
                    v-for="box in boxes_available_for_distribution"
                    :key="box.id"
                    :box="box"
                    class="my-3 mx-3"
                    :focusQuantity="box.duplicate - box.quantity_of_owners"
                    focusQuantityColor="secondary"
                    focusQuantityDescription="Available"
                    :removable="false"
                  />
                  <SButtonLoadmore
                    class="align-items-center"
                    store="warehouse.boxes_available_for_distribution"
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol col="12">
          <CCard>
            <CCardHeader class="d-flex align-items-center">
              <TMessage content="SKUs owned by order" bold>
                <template #suffix>
                  <span class="ml-1">{{ order_id_selected }}</span>
                </template>
              </TMessage>
              <TSpinner :loading="detail_loading" />
              <div class="ml-auto">
                <SButtonNextToLadingBill
                  v-if="order_selected.customer"
                  :customer="order_selected.customer"
                />
              </div>
            </CCardHeader>
            <CCardBody>
              <SInputSku @enter="enterSku" showQuantity />
              <TMessageNoData
                content="No SKU"
                v-if="!owners.filter((x) => x.box).length"
              />
              <CRow class="d-flex align-items-stretch">
                <SCardSku
                  v-for="owner in owners"
                  :key="owner.id"
                  :box="owner.box"
                  class="my-3 mx-3"
                  :focusQuantity="owner.quantity"
                  focusQuantityDescription="Distributed"
                  :focusQuantityEdiable="true"
                  @focus-quantity:change="updateQuantityOwner(owner, $event)"
                  refresh
                  removable
                  @remove="removeOwner(owner)"
                />
              </CRow>
              <SButtonLoadmore store="warehouse.owning_boxes" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import FilterForOrder from "../../components/FilterForOrder.vue";
import filterable from "../../mixins/filterable";

export default {
  components: { FilterForOrder },
  mixins: [filterable],
  created() {
    this.$store.dispatch("order.statuses.fetch.if-first-time");
  },
  data() {
    return {
      showFilterModal: false,
    };
  },
  mounted() {
    if (this.lodash.isEmpty(this.query)) {
      this.$store.dispatch("warehouse.assemble_orders.fetch.if-first-time");
    } else
      this.$store.dispatch("warehouse.assemble_orders.apply-query", this.query);
  },
  watch: {
    order_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("warehouse.assemble_orders.detail.fetch", id);
          this.fetchBoxesInSelectedOrder();
          this.fetchBoxesAvailableForDistribution();
        }
      },
    },
  },
  computed: {
    orders() {
      return this.$store.getters["warehouse.assemble_orders.list"];
    },
    list_fetching() {
      return this.$store.getters["warehouse.assemble_orders.fetching"];
    },
    order_id_selected() {
      return this.$route.query.order_id;
    },
    order_selected() {
      return this.$store.getters["warehouse.assemble_orders.detail"];
    },
    owners() {
      return this.$store.getters["warehouse.owning_boxes.list"];
    },
    detail_loading() {
      return this.$store.getters["warehouse.owning_boxes.loading"];
    },
    boxes_available_for_distribution() {
      return this.$store.getters[
        "warehouse.boxes_available_for_distribution.list"
      ];
    },
    boxes_available_for_distribution_loading() {
      return this.$store.getters[
        "warehouse.boxes_available_for_distribution.loading"
      ];
    },
  },
  methods: {
    fetchBoxesInSelectedOrder() {
      this.$store.dispatch("warehouse.owning_boxes.push-query", {
        "filter[order_id]": this.order_id_selected,
      });
    },
    fetchBoxesAvailableForDistribution() {
      this.$store.dispatch(
        "warehouse.boxes_available_for_distribution.apply-query",
        {
          "filter[order]": this.order_id_selected,
        }
      );
    },
    enterSku(sku, quantity) {
      this.$store
        .dispatch("warehouse.owning_boxes.create", {
          box_id: sku,
          order_id: this.order_id_selected,
          quantity: quantity || 1,
        })
        .then(() => {
          this.fetchBoxesInSelectedOrder();
          this.fetchBoxesAvailableForDistribution();
        });
    },
    updateQuantityOwner(owner, quantity) {
      this.$store
        .dispatch("warehouse.owning_boxes.update", {
          id: owner.id,
          attributes: { quantity },
        })
        .then(() => {
          this.fetchBoxesAvailableForDistribution();
        });
    },
    removeOwner(owner) {
      this.$store
        .dispatch("warehouse.owning_boxes.delete", owner.id)
        .then(() => {
          this.fetchBoxesAvailableForDistribution();
        });
    },
  },
};
</script>
